import { lazy } from 'react';

const ReservationList = lazy(() => import('./reservation-list'));;
const CreateReservation = lazy(() => import('./create-reservation'));;
const ReservationDetail = lazy(() => import('./reservation-detail'));;

export default {
  ReservationList,
  CreateReservation,
  ReservationDetail,
};
