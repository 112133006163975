const CHAIN_ROUTE_CONSTANTS = Object.freeze({
  ROOT: "/",
  DASHBOARD: "/dashboard",

  CHAINS: "/chains",

  CATEGORIES: "/categories",

  SCREENS: "/screens",

  ADVERTISERS: "/advertisers",

  BRANDS: "/brands",

  CAMPAIGNS: "/campaigns",

  CAMPAIGNS_DETAIL: "/campaigns/:id/detail",

  RESERVATIONS: "/reservations",

  RESERVATIONS_DETAIL: "/reservations/:id/detail",

  RESERVATIONS_CREATE: "/reservations/create",

  BRANCHES: "/branches",

  NOTIFICATIONS: "/notifications",

  CHAINS_BRANCHES: "/chains/:chainId/branches",

  CHAINS_BRANCHES_DETAIL: "/chains/:chainId/branches/:branchId/detail",

  RESTORE_PASSWORD: "/restorePassword",

  CHAIN: "/chain",

  USERS: "/users",
  USERS_CREATE: "/users/create",

  REPORTS: "/reports",
  REPORTS_SELLS: "/reports/sells",
  REPORTS_BRANCHES: "/reports/branches",
  REPORTS_CATEGORIES: "/reports/categories",
  TERMS_AND_CONDITIONS: "/termsandconditions",
  USER_CERTIFICATION: "/usercertification",
  RESEND_EMAILS: "/resendemails",
  ACCOUNT: "/account",
  CAMPAIGNS_CREATE: "/reservations/:id/campaigns/create",

});

export default CHAIN_ROUTE_CONSTANTS;
