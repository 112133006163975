export const ROOT = "/";
export const DASHBOARD = "/dashboard";

export const CHAINS = "/chains";
export const CHAINS_CREATE = "/chains/create";
export const CHAIN_DETAIL = "/chain/:id/detail";
export const CHAIN_EDIT = "/chain/:id/edit";
export const CHAINS_BRANCHES = "/chains/:chainId/branches";
export const CHAINS_BRANCHES_CREATE = "/chains/:chainId/branches/create";
export const CHAINS_BRANCHES_DETAIL =
  "/chains/:chainId/branches/:branchId/detail";
export const CHAINS_BRANCHES_EDIT = "/chains/:chainId/branches/:branchId/edit";

export const CATEGORIES = "/categories";
export const CATEGORY_DETAIL = "/category/:id/detail";

export const SCREENS = "/screens";
export const SCREENS_CREATE = "/screens/create";

export const ADVERTISERS = "/advertisers";
export const ADVERTISERS_CREATE = "/advertisers/create";

export const ADVERTISER_DETAIL = "/advertisers/:id/detail";
export const ADVERTISER_EDIT = "/advertisers/:id/edit";

export const BRANDS = "/brands";
export const BRAND_DETAIL = "/brands/:id/detail";

export const PRODUCTS = "/products";

export const CAMPAIGNS = "/campaigns";
export const CAMPAIGNS_CREATE = "/reservations/:id/campaigns/create";
export const CAMPAIGNS_EDIT = "/reservations/:id/campaigns/:campaignId";
export const CAMPAIGNS_DETAIL = "/campaigns/:id/detail";

export const RESERVATIONS = "/reservations";
export const RESERVATIONS_CREATE = "/reservations/create";
export const RESERVATIONS_DETAIL = "/reservations/:id/detail";

export const REPORTS = "/reports";
export const REPORTS_BRANCHES = "/reports/branches";
export const REPORTS_CATEGORIES = "/reports/categories";
export const REPORTS_ADVERTISERS = "/reports/advertisers";

export const USERS = "/users";
export const USERS_CREATE = "/users/create";
export const USER_EDIT = "/users/:id/edit";
export const USER_DETAIL = "/users/:id/detail";

export const NOTIFICATIONS = "/notifications";
export const CONFIGURATION = "/configuration";

export const RESTORE_PASSWORD = "/restorePassword";
export const TERMS_AND_CONDITIONS = "/termsandconditions";
export const USER_CERTIFICATION = "/usercertification";
export const RESEND_EMAILS = "/resendemails";
