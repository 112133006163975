import { ReactComponent as Cloud } from "./cloud_upload.svg";
import { ReactComponent as Backward } from "./24_ic_back.svg";
import { ReactComponent as Dashboard } from "./24_ic_dashboard.svg";
import { ReactComponent as Store } from "./24_ic_store.svg";
import { ReactComponent as List } from "./24_ic_list.svg";
import { ReactComponent as TvDisplay } from "./24_ic_tv_display.svg";
import { ReactComponent as Megaphone } from "./24_ic_megaphone.svg";
import { ReactComponent as Label } from "./24_ic_label_2.svg";
import { ReactComponent as Charts } from "./24_ic_charts.svg";
import { ReactComponent as Users } from "./24_ic_users.svg";
import { ReactComponent as Money } from "./24_ic_money.svg";
import { ReactComponent as Video } from "./24_ic_compaign.svg";
import { ReactComponent as chevron } from "./24_ic_chevron_left.svg";
import { ReactComponent as Forward } from "./24_ic_forward.svg";
import { ReactComponent as CircleCheck } from "./18__ic_check_circle.svg";
import { ReactComponent as CircleAdd } from "./18_ic_add.svg";
import { ReactComponent as OutlineCheck } from "./18_ic_check_outline.svg";
import { ReactComponent as UploadIcon } from "./18_ic_upload.svg";
import { ReactComponent as ChevronRight } from "./18_ic_chevron_right.svg";
import { ReactComponent as RemoveIcon } from "./18_ic_remove.svg";
import { ReactComponent as Close } from "./24_ic_close.svg";
import { ReactComponent as ChevronUp } from "./18_ic_chevron_up.svg";
import { ReactComponent as ChevronDown } from "./18_ic_chevron_down.svg";
import { ReactComponent as DropdownDown } from "./18_ic_Dropdown_Down.svg";
import { ReactComponent as DropdownUp } from "./18_ic_Dropdown_Up.svg";
import { ReactComponent as SearchIcon } from "./18_ic_search.svg";
import { ReactComponent as IconMore } from "./18_ic_more_v.svg";
import { ReactComponent as Delete } from "./18_ic_delete.svg";
import { ReactComponent as Edit } from "./18_ic_edit.svg";
import { ReactComponent as IconPhoto } from "./18_ic_photo_select.svg";
import { ReactComponent as Suspend } from "./18_ic_block.svg";
import { ReactComponent as Alert } from "./18_ic_alert.svg";
import { ReactComponent as ThynkadsLogo } from "./Logo.svg";
import { ReactComponent as Player } from "./18_ic_cast.svg";
import { ReactComponent as TvDisplayLive } from "./18_ic_tv_display_live.svg";
import { ReactComponent as Phone } from "./18_ic_phone.svg";
import { ReactComponent as Chat } from "./24_ic_chat.svg";
import { ReactComponent as info } from "./18_ic_info.svg";
import { ReactComponent as settings } from "./24_ic_settings.svg";
import { ReactComponent as Notification } from "./24_ic_notifications.svg";
import { ReactComponent as Flag } from "./24_ic_flag.svg";
import { ReactComponent as PowerSettings } from "./24_ic_power_settings.svg";
import { ReactComponent as Calendar } from "./18_ic_calendar.svg";
import { ReactComponent as ArrowRight } from "./18_ic_arrow_right.svg";
import { ReactComponent as NotificationPending } from "./24_ic_notificationspending.svg";
import { ReactComponent as Document } from "./24_ic_document.svg";

export default {
  Cloud,
  Backward,
  Dashboard,
  Store,
  List,
  TvDisplay,
  Megaphone,
  Label,
  Charts,
  Users,
  Money,
  Video,
  chevron,
  CircleCheck,
  CircleAdd,
  OutlineCheck,
  UploadIcon,
  ChevronRight,
  RemoveIcon,
  Close,
  ChevronUp,
  ChevronDown,
  DropdownDown,
  DropdownUp,
  SearchIcon,
  IconMore,
  Delete,
  Edit,
  IconPhoto,
  Suspend,
  Alert,
  ThynkadsLogo,
  Forward,
  Player,
  TvDisplayLive,
  Phone,
  Chat,
  info,
  settings,
  Notification,
  Flag,
  PowerSettings,
  Calendar,
  ArrowRight,
  NotificationPending,
  Document,
};
