export const reservations = {
  searchReservations: {
    method: "get",
    url: "/reservations",
  },
  getReservationValidYearsAndMonths: {
    method: "get",
    url: "/reservations/reports/validyearsandmonths",
  },
  sendReservationReport: {
    method: "post",
    url: "/reservations/reports/reservations",
  },
  getReservationById: {
    method: "get",
    url: "/reservations/:id",
  },
  createReservation: {
    method: "post",
    url: "/reservations",
  },
  updateReservationByOrderNumber: {
    method: "post",
    url: "/reservations/ordernumber",
  },
  updateReservationStatusById: {
    method: "post",
    url: "/reservations/updatestatus",
  },
  updateReservation: {
    method: "put",
    url: "/reservations/:id",
  },
  approveReservation: {
    method: "put",
    url: "/reservations/:id/approve",
  },
  declineReservation: {
    method: "put",
    url: "/reservations/:id/decline",
  },
  cancelReservation: {
    method: "put",
    url: "/reservations/:id/cancel",
  },
  checkoutReservation: {
    method: "patch",
    url: "/reservations/:id/checkout",
  },
  calculateReservationPrices: {
    method: "post",
    url: "/reservations/prices",
  },
  calculateReservationPricesPerBranches: {
    method: "post",
    url: "/reservations/pricesperbranches",
  },
  discardReservationChanges: {
    method: "patch",
    url: "/reservations/:id/discard",
  },
};
