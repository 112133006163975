import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormModal } from '../../../common/components/FormModal';
import icons from '../../../resources/assets/icons';
import { IMonitorsState, IMonitorsStateErrors } from '../../../common/store/monitors/MonitorsInterfaces';
import { getMonitorsError } from '../../../common/store/monitors/MonitorsSelectors';
import { IDeleteMonitorPayload } from '../../../common/payloads/monitors/IDeleteMonitorPayload';
import { deleteMonitor } from '../../../common/store/monitor/MonitorActions';
import { IUiState } from '../../../common/store/ui/IUiInterfaces';
import { toggleErrorModal } from '../../../common/store/ui/UiActions';

const mapStateToProps = (
  { ui }: { ui: IUiState },
) => ({
  ui,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators(
  {
    toggleErrorModal,
  },
  dispatch,
);

interface IGeneralErrorModalProps {
  toggleErrorModal: Function;
  ui: IUiState;
}

class GeneralErrorModal extends React.Component<IGeneralErrorModalProps> {

  static defaultProps = {
  };

  render() {
    return (
      <FormModal
        isOpen={this.props.ui.globalError.show}
        onClose={() => this.props.toggleErrorModal()}
        onSubmit={() => this.props.toggleErrorModal()}
        submitButtonLabel={'OK'}
        footerClassName="modal-footer"
        hideCloseButton
        submitClassName="w-20"
      >
        <div className="create-chain__modal">
          <div className="container">

            <div className="body">
              <p className="title">{this.props.ui.globalError.title}</p>
              {this.props.ui.globalError.message}
            </div>
          </div>
        </div>
      </FormModal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralErrorModal);
