import { lazy } from 'react';

const ChainReports = lazy(() => import('./ChainReports'));
const ChainSellsReports = lazy(() => import('./ChainReports'));
const ChainReportsAboutBranches = lazy(() => import('./chain-reports-about-branches'));
const ChainReportsAboutCategories = lazy(() => import('./chain-reports-about-categories'));

export default {
  ChainReports,
  ChainSellsReports,
  ChainReportsAboutBranches,
  ChainReportsAboutCategories,
};
