import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import lazyPages from "../pages/async";
import CHAIN_ROUTE_CONSTANTS from '../common/constants/routes/chain-routes.constants';

const chainAdminRoutes = () => (
  <Switch>
    <Redirect
      exact
      from={CHAIN_ROUTE_CONSTANTS.ROOT}
      to={CHAIN_ROUTE_CONSTANTS.RESERVATIONS}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.CHAIN}
      component={lazyPages.Chains.ChainAccount}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.ACCOUNT}
      component={lazyPages.Users.UserDetail}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.RESERVATIONS}
      component={lazyPages.Reservations.ReservationList}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.RESERVATIONS_DETAIL}
      component={lazyPages.Reservations.ReservationDetail}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.RESERVATIONS_CREATE}
      component={lazyPages.Reservations.CreateReservation}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.DASHBOARD}
      component={lazyPages.Dashboard.ChainAdminDashboard}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.RESTORE_PASSWORD}
      component={lazyPages.Authorization.RestorePassword}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.CHAINS}
      component={lazyPages.Chains.ChainList}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.CHAINS_BRANCHES}
      component={lazyPages.Chains.Branches.BranchList}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.SCREENS}
      component={lazyPages.Screens.ChainScreenList}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.BRANCHES}
      component={lazyPages.Chains.Branches.BranchList}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.CHAINS_BRANCHES_DETAIL}
      component={lazyPages.Chains.Branches.BranchDetail}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.CATEGORIES}
      component={lazyPages.Categories.CategoryList}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.ADVERTISERS}
      component={lazyPages.Advertisers.AdvertiserList}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.NOTIFICATIONS}
      component={lazyPages.Notifications.NotificationList}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.BRANDS}
      component={lazyPages.Brands.BrandList}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.REPORTS}
      component={lazyPages.Reports.ChainReports}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.CAMPAIGNS}
      component={lazyPages.Campaigns.CampaignList}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.CAMPAIGNS_CREATE}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      Path={CHAIN_ROUTE_CONSTANTS.CAMPAIGNS_DETAIL}
      component={lazyPages.Campaigns.CampaignDetail}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.REPORTS_BRANCHES}
      component={lazyPages.Reports.ChainReportsAboutBranches}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.REPORTS_CATEGORIES}
      component={lazyPages.Reports.ChainReportsAboutCategories}
    />

    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.USERS}
      component={lazyPages.Users.UserList}
    />
    <Route
      exact
      path={CHAIN_ROUTE_CONSTANTS.USERS_CREATE}
      component={lazyPages.Users.CreateUser}
    />
    <Route
      exact
      from={CHAIN_ROUTE_CONSTANTS.TERMS_AND_CONDITIONS}
      component={lazyPages.TermsAndConditions.TermsAndConditions}
    />
    <Route
      exact
      from={CHAIN_ROUTE_CONSTANTS.USER_CERTIFICATION}
      component={lazyPages.UserCertification.UserCertification}
    />
    <Route
      exact
      from={CHAIN_ROUTE_CONSTANTS.RESEND_EMAILS}
      component={lazyPages.ResendEmails.ResendEmails}
    />
  </Switch>
);

export default chainAdminRoutes;
