import { lazy } from 'react';

const AdminReportsAboutAdvertisers = lazy(() => import('./admin-reports-about-advertisers'));;
const AdminReportsAboutBranches = lazy(() => import('./admin-reports-about-branches'));;
const AdminReportsAboutCategories = lazy(() => import('./admin-reports-about-categories'));;

export default {
  AdminReportsAboutAdvertisers,
  AdminReportsAboutBranches,
  AdminReportsAboutCategories,
};
