const ADVERTISER_ROUTE_CONSTANTS = Object.freeze({
  ROOT: "/",
  DASHBOARD: "/dashboard",

  CAMPAIGNS: "/campaigns",
  CAMPAIGNS_DETAIL: "/campaigns/:id/detail",
  CAMPAIGNS_EDIT: "/reservations/:id/campaigns/:campaignId",
  CAMPAIGNS_CREATE: "/reservations/:id/campaigns/create",

  RESERVATIONS: "/reservations",
  RESERVATIONS_DETAIL: "/reservations/:id/detail",
  RESERVATIONS_EDIT: "/reservations/:id/edit",
  RESERVATIONS_CREATE: "/reservations/create",

  BRANDS: "/brands",
  BRAND_DETAIL: "/brands/:id/detail",

  PRODUCTS: "/products",

  RESTORE_PASSWORD: "/restorePassword",

  NOTIFICATIONS: "/notifications",

  REPORTS: "/reports",
  REPORTS_BRANDS: "/reports/brands",
  REPORTS_CAMPAIGNS: "/reports/campaigns",

  USERS: "/users",
  USER_DETAIL: "/users/:id/detail",
  USERS_CREATE: "/users/create",

  ACCOUNT: "/account",

  ACCOUNT_EDIT: "/account/:id/edit",
  TERMS_AND_CONDITIONS: "/termsandconditions",
  USER_CERTIFICATION: "/usercertification",
  RESEND_EMAILS: "/resendemails",
});

export default ADVERTISER_ROUTE_CONSTANTS;
