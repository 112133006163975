import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import ADVERTISER_ROUTE_CONSTANTS from "../common/constants/routes/advertiser-routes.constants";
import lazyPages from "../pages/async";

const advertiserRoutes = () => (
  <Switch>
    <Redirect
      exact
      from={ADVERTISER_ROUTE_CONSTANTS.ROOT}
      to={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.DASHBOARD}
      component={lazyPages.Dashboard.AdvertiserDashboard}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESTORE_PASSWORD}
      component={lazyPages.Authorization.RestorePassword}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.NOTIFICATIONS}
      component={lazyPages.Notifications.NotificationList}
    />

    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS}
      component={lazyPages.Campaigns.CampaignList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS_DETAIL}
      component={lazyPages.Campaigns.CampaignDetail}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS_EDIT}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS_CREATE}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS_EDIT}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS}
      component={lazyPages.Reservations.ReservationList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS_CREATE}
      component={lazyPages.Reservations.CreateReservation}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS_DETAIL}
      component={lazyPages.Reservations.ReservationDetail}
    />

    {/* <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CREDITS}
      component={lazyPages.Credits.PurchaseList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CREDITS_PURCHASE}
      component={lazyPages.Credits.CreatePurchaseOrder}
    />

    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.BRANDS}
      component={lazyPages.Brands.BrandList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.BRAND_DETAIL}
      component={lazyPages.Brands.BrandDetail}
    />

    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS}
      component={lazyPages.Reports.AdvertiserReports}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS_BRANDS}
      component={lazyPages.Reports.AdvertiserReportsAboutBrands}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS_CAMPAIGNS}
      component={lazyPages.Reports.AdvertiserReportsAboutCampaigns}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS_CREDITS}
      component={lazyPages.Reports.AdvertiserReportsAboutCredits}
    />

    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.USERS}
      component={lazyPages.Users.UserList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.USER_DETAIL}
      component={lazyPages.Users.UserDetail}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.USERS_CREATE}
      component={lazyPages.Users.CreateUser}
    /> */}

    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.ACCOUNT}
      component={lazyPages.Advertisers.AdvertiserAccount}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.ACCOUNT_EDIT}
      component={lazyPages.Advertisers.AdvertiserAccountEdit}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.PRODUCTS}
      component={lazyPages.Products.ProductList}
    />
    <Route
      exact
      from={ADVERTISER_ROUTE_CONSTANTS.TERMS_AND_CONDITIONS}
      component={lazyPages.TermsAndConditions.TermsAndConditions}
    />
    <Route
      exact
      from={ADVERTISER_ROUTE_CONSTANTS.USER_CERTIFICATION}
      component={lazyPages.UserCertification.UserCertification}
    />
    <Route
      exact
      from={ADVERTISER_ROUTE_CONSTANTS.RESEND_EMAILS}
      component={lazyPages.ResendEmails.ResendEmails}
    />

    <Route
      path="*"
      component={() => <Redirect to={ADVERTISER_ROUTE_CONSTANTS.DASHBOARD} />}
    />
  </Switch>
);

export default advertiserRoutes;
