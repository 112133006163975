import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../common/constants/routes/admin-routes.constants";
import lazyPages from "../pages/async";

const adminRouter = () => (
  <Switch>
    <Redirect exact from={routes.ROOT} to={routes.RESERVATIONS} />
    <Route
      exact
      path={routes.DASHBOARD}
      component={lazyPages.Dashboard.AdminDashboard}
    />
    <Route
      exact
      path={routes.RESTORE_PASSWORD}
      component={lazyPages.Authorization.RestorePassword}
    />
    <Route exact path={routes.CHAINS} component={lazyPages.Chains.ChainList} />
    <Route
      exact
      path={routes.CHAINS_CREATE}
      component={lazyPages.Chains.CreateChains}
    />
    <Route
      exact
      path={routes.CHAIN_DETAIL}
      component={lazyPages.Chains.ChainDetail}
    />
    <Route
      exact
      path={routes.CHAIN_EDIT}
      component={lazyPages.Chains.ChainEdit}
    />
    <Route
      exact
      path={routes.CHAINS_BRANCHES}
      component={lazyPages.Chains.Branches.BranchList}
    />
    <Route
      exact
      path={routes.CHAINS_BRANCHES_CREATE}
      component={lazyPages.Chains.Branches.CreateBranch}
    />
    <Route
      exact
      path={routes.CHAINS_BRANCHES_DETAIL}
      component={lazyPages.Chains.Branches.BranchDetail}
    />
    <Route
      exact
      path={routes.CHAINS_BRANCHES_EDIT}
      component={lazyPages.Chains.Branches.BranchEdit}
    />
    <Route
      exact
      path={routes.CATEGORIES}
      component={lazyPages.Categories.CategoryList}
    />
    <Route
      exact
      path={routes.CATEGORY_DETAIL}
      component={lazyPages.Categories.CategoryDetail}
    />
    <Route
      exact
      path={routes.SCREENS}
      component={lazyPages.Screens.ScreenList}
    />
    <Route
      exact
      path={routes.SCREENS_CREATE}
      component={lazyPages.Screens.CreateScreen}
    />
    <Route
      exact
      path={routes.ADVERTISERS}
      component={lazyPages.Advertisers.AdvertiserList}
    />
    <Route
      exact
      path={routes.ADVERTISER_DETAIL}
      component={lazyPages.Advertisers.AdvertiserDetail}
    />
    <Route
      exact
      path={routes.ADVERTISERS_CREATE}
      component={lazyPages.Advertisers.CreateAdvertiser}
    />
    <Route
      exact
      path={routes.ADVERTISER_EDIT}
      component={lazyPages.Advertisers.AdvertiserEdit}
    />
    <Route exact path={routes.BRANDS} component={lazyPages.Brands.BrandList} />
    <Route
      exact
      path={routes.BRAND_DETAIL}
      component={lazyPages.Brands.BrandDetail}
    />
    <Route
      exact
      path={routes.CAMPAIGNS}
      component={lazyPages.Campaigns.CampaignList}
    />
    <Route
      exact
      path={routes.CAMPAIGNS_CREATE}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      path={routes.CAMPAIGNS_EDIT}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      path={routes.CAMPAIGNS_DETAIL}
      component={lazyPages.Campaigns.CampaignDetail}
    />
    <Route
      exact
      path={routes.RESERVATIONS}
      component={lazyPages.Reservations.ReservationList}
    />
    <Route
      exact
      path={routes.RESERVATIONS_CREATE}
      component={lazyPages.Reservations.CreateReservation}
    />
    <Route
      exact
      path={routes.RESERVATIONS_DETAIL}
      component={lazyPages.Reservations.ReservationDetail}
    />
    <Route
      exact
      path={routes.REPORTS}
      component={lazyPages.Reports.ReportList}
    />
    <Route
      exact
      path={routes.REPORTS_ADVERTISERS}
      component={lazyPages.Reports.AdminReportsAboutAdvertisers}
    />
    <Route
      exact
      path={routes.REPORTS_BRANCHES}
      component={lazyPages.Reports.AdminReportsAboutBranches}
    />
    <Route
      exact
      path={routes.REPORTS_CATEGORIES}
      component={lazyPages.Reports.AdminReportsAboutCategories}
    />
    <Route exact path={routes.USERS} component={lazyPages.Users.UserList} />
    <Route
      exact
      path={routes.USERS_CREATE}
      component={lazyPages.Users.CreateUser}
    />
    <Route
      exact
      path={routes.USER_DETAIL}
      component={lazyPages.Users.UserDetail}
    />
    <Route
      exact
      path={routes.NOTIFICATIONS}
      component={lazyPages.Notifications.NotificationList}
    />
    <Route
      exact
      path={routes.CONFIGURATION}
      component={lazyPages.Configuration.ConfigurationEdit}
    />
    <Route
      exact
      path={routes.PRODUCTS}
      component={lazyPages.Products.ProductList}
    />
    <Route
      exact
      from={routes.TERMS_AND_CONDITIONS}
      component={lazyPages.TermsAndConditions.TermsAndConditions}
    />
    <Route
      exact
      from={routes.USER_CERTIFICATION}
      component={lazyPages.UserCertification.UserCertification}
    />
    <Route
      exact
      from={routes.RESEND_EMAILS}
      component={lazyPages.ResendEmails.ResendEmails}
    />
  </Switch>
);

export default adminRouter;
